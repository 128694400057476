import React from 'react';
import NavBar from './NavBar';
import Footer from './Footer';
import { TiLocation } from "react-icons/ti";
import { FaClock } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import banner from '../assets/MNCH.png'




const Soon = () => {
  return (
    <div className='flex flex-col w-screen'>
      <div className='flex z-50 absolute'><NavBar /></div>
      <div className='flex flex-col h-[33rem] justify-end items-center bg-cover bg-center' style={{ backgroundImage: `url(${banner})` }}>
        <span className=' text-6xl w-[45rem] text-center items-center justify-center flex font-bold text-[#E2E7E9]/90 tracking-wider'>Maternal, Newborn </span>
        <span className=' text-6xl w-[45rem] text-center items-center justify-center flex font-bold text-[#E2E7E9]/90 mb-28 tracking-wider'> and child health project</span>
      </div>
      <div className='flex flex-col py-10 items-center gap-5'>
        <div className='flex flex-row gap-12 justify-center items-center'>
          <span className='text-lg font-medium opacity-80 w-[42rem] '>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptatum commodi neque molestiae reiciendis unde consequuntur at quo incidunt autem saepe distinctio mollitia dignissimos vitae, quis ut quisquam perferendis magni dolores.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptatum commodi neque molestiae reiciendis unde consequuntur at quo incidunt autem saepe distinctio mollitia dignissimos vitae, quis ut quisquam perferendis magni dolores.</span>
          <span className='w-96 h-80 bg-[#6100c1] rounded-md flex relative'>
            <span className='w-96 h-80 bg-[#000000]/90 rounded-md absolute top-6 left-6'></span>
          </span>
        </div>
        <div className='flex flex-row gap-12 justify-center items-center'>
          <span className='w-96 h-80 bg-[#6100c1] rounded-md flex relative'>
            <span className='w-96 h-80 bg-[#000000]/90 rounded-md absolute top-6 right-6'></span>
          </span>
          <span className='text-lg font-medium opacity-80 w-[42rem] '>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptatum commodi neque molestiae reiciendis unde consequuntur at quo incidunt autem saepe distinctio mollitia dignissimos vitae, quis ut quisquam perferendis magni dolores.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptatum commodi neque molestiae reiciendis unde consequuntur at quo incidunt autem saepe distinctio mollitia dignissimos vitae, quis ut quisquam perferendis magni dolores.</span>
        </div>
      </div>
      <div className='flex flex-col py-10 items-center gap-5'>
        <span className='flex text-3xl font-semibold opacity-90'>Project Managers</span>
        <div className='flex gap-3'>
          <span className='w-48 h-48 rounded-md bg-black/30'></span>
          <span className='w-48 h-48 rounded-md bg-black/40'></span>
          <span className='w-48 h-48 rounded-md bg-black/50'></span>
          <span className='w-48 h-48 rounded-md bg-black/60'></span>
          <span className='w-48 h-48 rounded-md bg-black/70'></span>
          <span className='w-48 h-48 rounded-md bg-black/80'></span>
        </div>
      </div>
      <div className='flex flex-col py-10 items-center gap-5'>
        <span className='flex text-2xl font-semibold opacity-90'>Join other champions and stakeothers for upcoming submits</span>
        <div className='flex flex-row py-3 gap-10'>
          <span className='flex flex-col p-5 gap-3 rounded-md bg-[#6100c1]'>
            <span className='text-2xl font-semibold text-[#E2E7E9]/90'>Transforming together submit Abuja</span>
            <span className='flex gap-2 items-center'>
                <FaClock className='text-[#E2E7E9]/90' size={18}/>
                <span className='text-xl font-normal text-[#E2E7E9]/90 '>Wed 6th March, 2024</span>
            </span>
            <span className='flex gap-2 items-center'>
                <TiLocation className='text-[#E2E7E9]/90' size={20}/>
                <span className='text-xl font-normal text-[#E2E7E9]/90 '> 1684 Sanusi Fafunwa St</span>
            </span>
            <Link to='/abuja-submit'><span className='flex items-center px-3 py-3 border-none rounded-md text-[#6100c1]/80 w-fit mt-5 font-semibold text-xl bg-[#E2E7E9]/90'>Save your spot</span></Link>
          </span>
          <span className='flex flex-col p-5 gap-3 rounded-md bg-[#6100c1]'>
            <span className='text-2xl font-semibold text-[#E2E7E9]/90'>Transforming together submit Lagos</span>
            <span className='flex gap-2 items-center'>
                <FaClock className='text-[#E2E7E9]/90' size={18}/>
                <span className='text-xl font-normal text-[#E2E7E9]/90 '>Wed 6th March, 2024</span>
            </span>
            <span className='flex gap-2 items-center'>
                <TiLocation className='text-[#E2E7E9]/90' size={20}/>
                <span className='text-xl font-normal text-[#E2E7E9]/90 '> 1684 Sanusi Fafunwa St</span>
            </span>
            <Link to='/lagos-submit'><span className='flex items-center px-3 py-3 border-none rounded-md text-[#6100c1]/80 w-fit mt-5 font-semibold text-xl bg-[#E2E7E9]/90'>Save your spot</span></Link>
          </span>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Soon