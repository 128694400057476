import {React, useEffect } from 'react';
import Logo from '../assets/new6.png';
import { FaLinkedinIn,  FaInstagram, FaTwitter, FaYoutube, FaClock } from 'react-icons/fa';
import { SlCalender } from "react-icons/sl";
import { TiLocation } from "react-icons/ti";
import NavBar from './NavBar';


const Abuja = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div className='flex flex-col w-screen'>
        <div className='flex z-50 absolute'><NavBar /></div>
        <div className='flex flex-col bg-[#000000]/90 h-[30rem] justify-end items-center'>
            <span className=' text-5xl font-bold text-[#E2E7E9]/90'>Transforming Together Submit Abuja </span>
            <span className='flex items-center px-5 py-2 border-none rounded-md text-[#6100c1]/80 w-fit mt-5 font-medium text-xl bg-[#E2E7E9]/90 mb-20'>Register</span>
        </div>
        <div className='flex justify-between items-center py-10 px-16 bg-[#6100c1]/90'>
            <span className='flex gap-2 items-center justify-center w-[22rem]'>
                <TiLocation className='text-[#E2E7E9]/90' size={30}/>
                <span className='text-2xl font-semibold text-[#E2E7E9]/90 '> 1684 Sanusi Fafunwa St</span>
            </span>
            <span className='flex gap-2 items-center justify-center w-[22rem]'>
                <FaClock className='text-[#E2E7E9]/90' size={30}/>
                <span className='text-2xl font-semibold text-[#E2E7E9]/90 '>Wed 6th March, 2024 : 10am</span>
            </span>
            <span className='flex gap-2 items-center justify-center w-[22rem]'>
                <SlCalender className='text-[#E2E7E9]/90' size={30}/>
                <span className='text-2xl font-semibold text-[#E2E7E9]/90 '></span>
            </span>
            
      </div>
      <div className='flex flex-row gap-12 justify-center items-center py-10'>
          <span className='text-lg font-medium opacity-80 w-[42rem] '>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptatum commodi neque molestiae reiciendis unde consequuntur at quo incidunt autem saepe distinctio mollitia dignissimos vitae, quis ut quisquam perferendis magni dolores.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptatum commodi neque molestiae reiciendis unde consequuntur at quo incidunt autem saepe distinctio mollitia dignissimos vitae, quis ut quisquam perferendis magni dolores reiciendis unde consequuntur at quo incidunt autem saepe distinctio mollitia dignissimos vitae, quis ut quisquam perferendis magni dolores.</span>
          <span className='w-96 h-80 bg-[#6100c1] rounded-md flex relative'>
            <span className='w-96 h-80 bg-[#000000]/90 rounded-md absolute top-6 left-6'></span>
          </span>
      </div>
      <div className='flex flex-col py-10 items-center gap-5'>
        <span className='flex text-3xl font-semibold opacity-90'>Speakers</span>
        <div className='flex gap-6'>
          <span className='w-56 h-56 rounded-md bg-black/50'></span>
          <span className='w-56 h-56 rounded-md bg-black/60'></span>
          <span className='w-56 h-56 rounded-md bg-black/70'></span>
          <span className='w-56 h-56 rounded-md bg-black/80'></span>
        </div>
      </div>
      <div className='flex items-center justify-center gap-16 py-5'>
        <span className='w-[45rem] h-80 bg-[#000000]/90 rounded-md'></span>
        <div className='flex flex-col gap-5'>
            <span className='text-4xl font-semibold w-72'>Watch Our Preview</span>
            <span className='text-2xl font-medium w-80'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. </span>
            <span className='flex items-center px-5 py-2 border-none rounded-md text-[#6100c1]/80 w-fit font-medium text-xl bg-[#E2E7E9]/90'>More Videos..</span>
        </div>
        
      </div>
      <div className='flex gap-36 bg-[#6100c1]/90 py-10 justify-center items-center'>
        <div className='flex flex-col gap-3 '>
            <div className='flex justify-center'>
                <img src={Logo} className='lg:w-20 w-16 h-16 lg:h-20 opacity-90' alt=''></img>
            </div>
            <div className='flex lg:gap-3 gap-1 justify-center'>
                <span className='p-1 bg-[#E2E7E9]/80 rounded-md'><a href='https://www.linkedin.com/company/evgnigeria/?viewAsMember=true' rel='noreferrer'><FaLinkedinIn className='lg:w-4 w-2 h-2 lg:h-4 text-[#000000]/90'/></a></span>
                <span className='p-1 bg-[#E2E7E9]/80 rounded-md'><a href='https://www.instagram.com/edenventuregroup/?hl=en' rel='noreferrer'><FaInstagram className='lg:w-4 w-2 h-2 lg:h-4 text-[#000000]/90'/></a></span>
                <span className='p-1 bg-[#E2E7E9]/80 rounded-md'><a href='https://twitter.com/weecreateafrica?s=21&t=DspMjJG2ePwZzOqrDbEHUw' rel='noreferrer'><FaTwitter className='lg:w-4 w-2 h-2 lg:h-4 text-[#000000]/90'/></a></span>
                <span className='p-1 bg-[#E2E7E9]/80 rounded-md'><a href='https://www.youtube.com/@weecreateafrica?sub_confirmation=1' rel='noreferrer'><FaYoutube className='lg:w-4 w-2 h-2 lg:h-4 text-[#000000]/90'/></a></span>
            </div>
        </div>
        <span className='flex flex-col p-4 bg-white rounded-md z-20 lg:mt-0 mt-2 w-fit '>
            <span className='text-xl font-medium flex justify-center mb-2 opacity-80'> Share your story about mental health</span>
            <input className=" w-72 h-12 mb-2 border-b-2 border-black/10 px-3 bg-transparent text-[#061c56] outline-none" placeholder="Name"></input>
            <input className=" w-72 h-12 mb-2 border-b-2 border-black/10 px-3 bg-transparent text-[#061c56] outline-none" placeholder="email address..."></input>
            <input className=" w-72 h-20 mb-4 border-b-2 border-black/10 px-3 bg-transparent text-[#061c56] outline-none" placeholder="Story"></input>
            <span className='flex justify-center'><input className= 'px-6 py-2 w-fit rounded  bg-[#E2E7E9]/90 text-[#6100c1]/80 uppercase text-sm font-medium hover:text-white' type='submit' value='send message'></input></span>
        </span>
      </div>
    </div>
  )
}

export default Abuja;