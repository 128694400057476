import React from "react";
import Soon from "./components/Soon";
import { Routes, Route} from 'react-router-dom';
import Abuja from "./components/Abuja";
import Lagos from "./components/Lagos";

function App() {
  return (
    <Routes>
        <Route path='/' element={<Soon />}></Route>
        <Route path='abuja-submit' element={<Abuja />}></Route>
        <Route path='Lagos-submit' element={<Lagos />}></Route>
    </Routes>
  );
}

export default App;
